import { ValidationIssue } from '../classes/ValidationIssue';
import { ValidationScopes, ValidationSource } from '../models/enums';
import { IContract, IPricingItem } from '../models';
import { CorePricingGridColumnFields, DataGridCellMessage } from '@dierbergs-markets/react-feature-library';
import { IReferenceDataState } from '../contexts/ApplicationContext';
import { getDistinctStores } from '../utilities/ContractUtilities';

/**
 * Converts DataGridCellMessage to ValidationIssue for pricing validation
 */
function convertPricingGridMessageToValidationIssue(message: DataGridCellMessage): ValidationIssue {
  return new ValidationIssue({
    scope: ValidationScopes.Pricings,
    field: message.field,
    message: message.message,
    identifier: message.rowId,
    source: ValidationSource.Ui,
  });
}

/**
 * Validates pricing data applying both grid-level and contract-level validation rules
 */
function validateContractPricing(contract: IContract, referenceData: IReferenceDataState): ValidationIssue[] {
  const errors: ValidationIssue[] = [];

  if (!contract.pricings) return errors;

  contract.pricings.forEach((pricing, pricingIndex) => {
    pricing.items.forEach((pi) => {
      errors.push(...validatePricingItem(contract, pi, pricingIndex, referenceData));
    });
  });

  return errors;
}

function validatePricingItem(
  contract: IContract,
  pricingItem: IPricingItem,
  indexPos: number,
  referenceData: IReferenceDataState
): ValidationIssue[] {
  const errors: ValidationIssue[] = [];

  const item =
    contract.terms.contractItems.find((q) => q.sku === pricingItem.sku)?.item ??
    contract.terms.contractItems.flatMap((ci) => ci.childData).find((q) => q?.item?.sku === pricingItem.sku)?.item;
  if (!item) return [];

  // Validate pricing values
  if (pricingItem.price && item?.regularPrices) {
    const contractStoreIds = getDistinctStores(contract.terms.stores, referenceData).map((s) => s.id);

    const applicableRegularPrices = item.regularPrices.filter((rp) => rp.stores.some((storeId) => contractStoreIds.includes(storeId)));

    const isLowerThanAtLeastOne = applicableRegularPrices.some((rp) => pricingItem.price && pricingItem.price / pricingItem.multiple <= rp.price);

    if (!isLowerThanAtLeastOne) {
      errors.push(
        new ValidationIssue({
          scope: ValidationScopes.Pricings,
          field: CorePricingGridColumnFields.Price_ByUniqueId(indexPos),
          message: `${item?.upc ?? pricingItem.sku}: Price must be lower than or equal to at least one store's Regular Price on the contract.`,
          identifier: pricingItem.sku.toString(),
          source: ValidationSource.Ui,
        })
      );
    }
  }
  return errors;
}

/**
 * Helper to determine if a validation message already exists
 */
function findMatchingValidationIssue(issues: ValidationIssue[], scope: string, field: string, identifier: string): ValidationIssue | undefined {
  return issues.find((issue) => issue.scope === scope && issue.field === field && issue.identifier === identifier);
}

export const pricingValidationService = {
  findMatchingValidationIssue,
  convertPricingGridMessageToValidationIssue,
  validateContractPricing,
  validatePricingItem,
};
