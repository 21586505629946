import { Popover } from '@dierbergs-markets/react-component-library';
import { IStore, IStoreSelection } from '../../../../models';
import { MdStore, MdOutlineStore } from 'react-icons/md';
import { tss } from 'tss-react';
import { defaultColors } from '../../../../styles/variables';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { getDistinctStores } from '../../../../utilities/ContractUtilities';

export default function SelectedStoresIconPopover(props: { stores: IStoreSelection }) {
  const { classes, css } = useStyles();
  const { referenceData } = useApplicationContextState();

  function isAnyStoreSelected(storeSelections: IStoreSelection) {
    return storeSelections.storeGroupIds.length > 0 || storeSelections.storeIds.length > 0;
  }

  // SUB-COMPONENTS
  function StoreIcon(props: { stores: IStoreSelection }) {
    const { stores } = props;

    const hasStores = isAnyStoreSelected(stores);

    const iconToDisplay = hasStores ? (
      <MdStore className={css(classes.storeIcon)} />
    ) : (
      <MdOutlineStore className={css(classes.storeIcon, { color: defaultColors.mediumBlueGrey })} />
    );

    return (
      <div className={css(classes.stores)}>
        {iconToDisplay}
        {hasStores && (
          <div className={css(classes.storeCountBadge)}>
            <div className={css(classes.storeCount)}>{getDistinctStores(stores, referenceData).length}</div>
          </div>
        )}
      </div>
    );
  }

  function StorePopoverContent(props: { stores: IStoreSelection }) {
    const { stores } = props;

    const hasStores = isAnyStoreSelected(stores);

    if (!hasStores) return <div>no stores</div>;

    return (
      <>
        {getDistinctStores(stores, referenceData).map((store) => (
          <div key={store.id}>{store.displayName}</div>
        ))}
      </>
    );
  }

  return (
    <Popover
      id={'stores'}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      buttonProps={{
        variant: 'icon',
        children: <StoreIcon stores={props.stores} />,
        classes: {
          root: css({
            '&&:hover': { backgroundColor: 'unset' },
            '&&:focus-visible': { outline: 0 },
          }),
        },
      }}
      classes={{
        popoverContent: css(classes.storesPopoverContent),
      }}
    >
      <StorePopoverContent stores={props.stores} />
    </Popover>
  );
}

const useStyles = tss.create({
  root: {},
  storesPopoverContent: {
    width: '250px',
    maxHeight: '550px',
  },
  stores: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  storeCount: {
    fontSize: '10px',
    fontWeight: 600,
    color: defaultColors.white,
    marginLeft: '6px',
    marginRight: '6px',
  },
  storeCountBadge: {
    minWidth: '21px',
    maxWidth: '32px',
    backgroundColor: defaultColors.blue,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    border: `1px solid ${defaultColors.white}`,
    marginLeft: '-10px',
    marginTop: '-10px',
  },
  storeIcon: {
    fontSize: '22px',
    color: defaultColors.darkGrey,
    cursor: 'pointer',
    '&&:hover': {
      backgroundColor: 'unset',
    },
  },
});
