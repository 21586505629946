import { DataGridCellMessage } from '@dierbergs-markets/react-component-library';
import { ValidationIssue } from '../classes/ValidationIssue';

export function toDataGridCellMessage(issue: ValidationIssue) {
  return new DataGridCellMessage({
    field: issue.field ?? '',
    rowId: issue.identifier,
    type: issue.severity,
    message: issue.message,
  });
}
