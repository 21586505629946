import {
  Button,
  Checkbox,
  DataGridCellMessage,
  DataGridEditableCellOverrideParams,
  DataGridRenderBodyCellParams,
  DataGridValueGetterParams,
  DataGridValueSetterParams,
} from '@dierbergs-markets/react-component-library';
import { IReferenceDataState } from '../contexts/ApplicationContext';
import { IContract, IContractItem, IContractTerms, IItem, IPricing, IPricingItem, IPricingItemTerm, IPricingItemTermItem } from '../models';
import { defaultColors } from '../styles/variables';
import * as PG from '@dierbergs-markets/react-feature-library';
import { format } from 'date-fns';
import { CostImplicationType } from '@dierbergs-markets/react-feature-library';
import { ValidationIssue } from '../classes/ValidationIssue';
import { ValidationSource } from '../models/enums';
import { getDistinctStores } from './ContractUtilities';

export interface PricingGridPricingItem extends PG.IPricingItem {
  suggestedRetailPrice?: number;
  suggestedRetailMultiple?: number;

  source?: IPricingSource; //the selected, most-expensive source
  sources: IPricingSource[];
}

export interface IPricingSource {
  shipper: IShipperCostWorksheet | null;
  caseListCost?: number;
  quantity: number;
  caseCost: number | undefined;
  caseReductions: number;
  unitCost: number | undefined;
  unitReductions: number;
}

export interface ISourcedPricingItem {
  sku: number;
  item: IItem;
  suggestedRetailPrice?: number;
  suggestedRetailMultiple?: number;

  source?: IPricingSource; //the selected, most-expensive source
  sources: IPricingSource[];
}

interface IShipperCostWorksheet {
  item: IItem;
  contents: IShipperItemCostWorksheet[];
  caseListCost?: number;
}

interface IShipperItemCostWorksheet {
  item?: IItem;
  quantity?: number;
  regularUnitCost?: number;
  regularCaseListCost?: number;
  ratio?: number;
  caseListCost?: number;
  unitListCost?: number;
}

interface IPricingCostImplication extends PG.ICostImplication {
  itemTerm: IPricingItemTerm;
  type: PG.CostImplicationType;
}

function computeShipperCostWorksheet(contractItem: IContractItem): IShipperCostWorksheet | null {
  if (!contractItem.item) return null;

  const contentRegularCosts =
    contractItem.childData?.map((content) => {
      const quantity = content.item?.quantityPerPack;
      //choose the highest regular cost
      const regularUnitCost = content.item ? getMax(content.item.regularPrices.map((r) => r.price / r.multiple)) : undefined;
      const regularCaseListCost = quantity && regularUnitCost ? quantity * regularUnitCost : undefined;
      return {
        item: content.item,
        quantity: quantity,
        regularUnitCost: regularUnitCost,
        regularCaseListCost: regularCaseListCost,
      } as Omit<Omit<Omit<IShipperItemCostWorksheet, 'caseListCost'>, 'unitListCost'>, 'ratio'>;
    }) ?? [];
  if (contentRegularCosts.length === 0) return null;

  const totalRegularCaseListCost = sumIfKnown(contentRegularCosts, (rc) => rc.regularCaseListCost);

  const contentCosts = contentRegularCosts.map((rc) => {
    const ratio = !rc.regularCaseListCost || !totalRegularCaseListCost ? undefined : rc.regularCaseListCost / totalRegularCaseListCost;
    const caseListCost = !ratio || !contractItem.caseListCost ? undefined : ratio * contractItem.caseListCost;
    const unitListCost = !caseListCost || !rc.quantity ? undefined : caseListCost / rc.quantity;
    return { ...rc, ratio: ratio, caseListCost: caseListCost, unitListCost: unitListCost } as IShipperItemCostWorksheet;
  });

  return {
    item: contractItem.item,
    caseListCost: contractItem.caseListCost,
    contents: contentCosts,
  } as IShipperCostWorksheet;
}

function addIfKnown(a: number | undefined, b: number | undefined): number | undefined {
  return a === undefined || b === undefined ? undefined : a + b;
}

function sumIfKnown<T>(array: T[], selector: (item: T) => number | undefined): number | undefined {
  return array.reduce((accumulator, item) => addIfKnown(accumulator, selector(item)), 0 as number | undefined);
}

class SourcedPricingItemsBuilder {
  constructor() {
    this.items = {};
  }

  items: Record<number, Omit<ISourcedPricingItem, 'source'>>;

  getItems() {
    return Object.values(this.items);
  }

  addItemSource(contractItem: IContractItem, contract: IContract, referenceData: IReferenceDataState) {
    if (!contractItem.item) return;

    if (!contractItem.childData || contractItem.childData.length === 0) {
      //open stock
      const costImplications = createCostImplications(contractItem.sku, contractItem.sku, 1, contract, referenceData);
      const item = this.addSource(
        contractItem.sku,
        contractItem.item,
        this.createPricingSource(null, costImplications, contractItem.item.quantityPerPack, contractItem.caseListCost)
      );
      item.suggestedRetailPrice = contractItem.suggestedRetailPrice;
      item.suggestedRetailMultiple = contractItem.suggestedRetailMultiple;
    } else {
      //shipper
      const shipperWorksheet = computeShipperCostWorksheet(contractItem);
      if (!shipperWorksheet) return;
      for (const content of contractItem.childData) {
        if (!content.item) continue;
        const contentCost = shipperWorksheet.contents.find((c) => c.item?.sku === content.sku);
        const caseRatio = getCaseRatio(shipperWorksheet, contentCost?.caseListCost);
        const costImplications = createCostImplications(shipperWorksheet.item.sku, content.sku, caseRatio, contract, referenceData);
        this.addSource(
          content.sku,
          content.item,
          this.createPricingSource(shipperWorksheet, costImplications, content.item.quantityPerPack, contentCost?.caseListCost)
        );
      }
    }
  }

  private addSource(sku: number, item: IItem, source: IPricingSource): ISourcedPricingItem {
    const pricingItem = this.getOrCreate(sku, item);
    pricingItem.sources.push(source);
    return pricingItem;
  }

  private getOrCreate(sku: number, item: IItem): Omit<ISourcedPricingItem, 'source'> {
    const existing = this.items[sku];
    if (existing) return existing;

    const newItem = {
      sku: sku,
      item: item,
      sources: [],
    } as Omit<ISourcedPricingItem, 'source'>;
    this.items[sku] = newItem;
    return newItem;
  }

  private createPricingSource(
    shipperWorksheet: IShipperCostWorksheet | null,
    costImplications: IPricingCostImplication[],
    quantity: number,
    caseListCost: number | undefined
  ): IPricingSource {
    return {
      shipper: shipperWorksheet,
      quantity: quantity,
      caseListCost: caseListCost,
      caseCost: computeCaseCost(caseListCost, costImplications),
      caseReductions: computeCaseReductions(costImplications),
      unitCost: computeUnitCost(caseListCost, quantity, costImplications),
      unitReductions: computeUnitOnlyReductions(quantity, costImplications),
    };
  }
}

function gatherContractItemsWithSources(contract: IContract, referenceData: IReferenceDataState): Omit<ISourcedPricingItem, 'source'>[] {
  const builder = new SourcedPricingItemsBuilder();
  for (const contractItem of contract.terms.contractItems) {
    builder.addItemSource(contractItem, contract, referenceData);
  }
  return builder.getItems();
}

export function getContractPricingItems(contract?: IContract, referenceData?: IReferenceDataState): PricingGridPricingItem[] {
  if (!contract || !referenceData) return [];
  const sourcedItemsWithoutSelection = gatherContractItemsWithSources(contract, referenceData);
  const sourceItemsWithSelection = sourcedItemsWithoutSelection.map((item) => {
    return {
      ...item,
      source: findMax<IPricingSource>(item.sources, (s) => s.unitCost),
    } as ISourcedPricingItem;
  });

  return sourceItemsWithSelection.map((pricingItem) => {
    const contractStoreIds = getDistinctStores(contract.terms.stores, referenceData).map((s) => s.id);
    const applicableRegularPrices = pricingItem.item.regularPrices.filter((rp) => rp.stores.some((storeId) => contractStoreIds.includes(storeId)));
    const highestRegularPrice =
      applicableRegularPrices.length > 0
        ? applicableRegularPrices.reduce((max, current) => (current.price > max.price ? current : max), applicableRegularPrices[0])
        : null;

    return {
      rowType: 'Parent',
      item: createPricingGridItem(
        pricingItem.sku,
        pricingItem.item,
        contract.terms.supplier?.id,
        pricingItem.source?.caseListCost,
        pricingItem.source?.quantity
      ),
      costImplications: createCostImplicationsForPricingItem(pricingItem, contract, getPricingSourceCaseRatio(pricingItem.source), referenceData),
      prices: extractPricesBySku(pricingItem.sku, contract.pricings),
      //show any regular price until the pricing plugin is ready for multiple
      currentRegPrice: highestRegularPrice
        ? {
            price: highestRegularPrice.price,
            multiple: highestRegularPrice.multiple,
          }
        : { price: undefined, multiple: undefined },
      suggestedRetailPrice: pricingItem.suggestedRetailPrice,
      suggestedRetailMultiple: pricingItem.suggestedRetailMultiple,
      source: pricingItem.source,
      sources: pricingItem.sources,
    };
  });
}

function getPricingSourceCaseRatio(source?: IPricingSource) {
  if (!source) return undefined;
  return getCaseRatio(source.shipper, source.caseListCost);
}

function getCaseRatio(shipperWorksheet: IShipperCostWorksheet | null, itemCaseListCost: number | undefined) {
  if (!shipperWorksheet) return 1;
  if (!itemCaseListCost || !shipperWorksheet.caseListCost) return undefined;
  return itemCaseListCost / shipperWorksheet.caseListCost;
}

function findMax<T>(items: T[], selector: (item: T) => number | undefined): T | undefined {
  const maximumItemAndValue = items.reduce((max: { item: T; value: number } | undefined, currentItem: T) => {
    const currentValue = selector(currentItem);
    return currentValue !== undefined && (max === undefined || currentValue > max.value) ? { item: currentItem, value: currentValue } : max;
  }, undefined);
  return maximumItemAndValue?.item;
}

function getMax(items: number[]): number | undefined {
  const maximumValue = items.reduce((max: number | undefined, current: number | undefined) => {
    return current !== undefined && (max === undefined || current > max) ? current : max;
  }, undefined);
  return maximumValue;
}

function getCostImplicationType(unitOfMeasureId: number, referenceData?: IReferenceDataState) {
  const uom = referenceData?.termTypeUnitsOfMeasure.byId[unitOfMeasureId];

  switch (uom?.name.toUpperCase()) {
    case 'CASE':
      return PG.CostImplicationType.CASE;

    default:
      return PG.CostImplicationType.SINGLEUNIT;
  }
}

function createCostImplicationsForPricingItem(
  pricingItem: ISourcedPricingItem,
  contract: IContract,
  caseRatio: number | undefined,
  referenceData: IReferenceDataState
): IPricingCostImplication[] {
  const itemSku = pricingItem.sku;
  const caseSku = pricingItem.source?.shipper?.item.sku ?? pricingItem.sku;
  return createCostImplications(caseSku, itemSku, caseRatio, contract, referenceData);
}

function createCostImplications(
  caseSku: number,
  itemSku: number,
  caseRatio: number | undefined,
  contract: IContract,
  referenceData: IReferenceDataState
): IPricingCostImplication[] {
  if (!contract.pricings) return [];
  return distinctByKey(
    contract.pricings.flatMap((x) => x.itemTerms),
    (itemTerm) => itemTerm.itemTermUniqueId
  ).map((itemTerm) => createCostImplication(itemTerm, caseSku, itemSku, caseRatio, contract.terms, referenceData));
}

function distinctByKey<TItem, TKey>(array: TItem[], id: (item: TItem) => TKey) {
  return array.reduce((acc: TItem[], item: TItem) => {
    if (!acc.find((existing) => id(existing) === id(item))) acc.push(item);
    return acc;
  }, []);
}

function createCostImplication(
  itemTerm: IPricingItemTerm,
  caseSku: number,
  itemSku: number,
  caseRatio: number | undefined,
  contractTerms: IContractTerms,
  referenceData: IReferenceDataState
): IPricingCostImplication {
  const costImplicationType = getCostImplicationType(itemTerm.termUnitOfMeasureId, referenceData);
  const sku =
    costImplicationType === CostImplicationType.CASE ? caseSku : costImplicationType === CostImplicationType.SINGLEUNIT ? itemSku : undefined;
  const ratio = costImplicationType === CostImplicationType.CASE ? caseRatio : costImplicationType === CostImplicationType.SINGLEUNIT ? 1 : undefined;

  if (!sku || !ratio) return createPricingCostImplication(costImplicationType, itemTerm, 0);

  const amount =
    getItemAmountFromContractTerms(contractTerms, itemTerm.itemTermUniqueId, sku) ?? getItemAmountFromItemTerms(itemTerm.items, sku) ?? 0;

  return createPricingCostImplication(costImplicationType, itemTerm, amount * ratio);
}

function createPricingCostImplication(type: PG.CostImplicationType, itemTerm: IPricingItemTerm, amount: number): IPricingCostImplication {
  return { type: type, uniqueId: itemTerm.itemTermUniqueId, itemTerm: itemTerm, amount: amount };
}

function getItemAmountFromItemTerms(itemTermItems: IPricingItemTermItem[], sku: number): number | undefined {
  const item = itemTermItems.find((i) => i.sku === sku);

  if (item) return item?.amount ?? 0;
  return undefined;
}

function getItemAmountFromContractTerms(contractTerms: IContractTerms, itemTermUniqueId: string, sku: number): number | undefined {
  //check if the itemTermUniqueId is on this contract.
  const localContractTermIndex = contractTerms.contractTermsForItem.findIndex((cti) => cti.uniqueId === itemTermUniqueId);

  if (localContractTermIndex !== -1) {
    //If the contractItem exists but has not been included in itemTermItems, get the amount from contractItem.amounts array
    //This happens when new UPCs are added to the same contract during the current session but not saved yet.
    const localContractItem = contractTerms.contractItems.find((ci) => ci.sku === sku);
    if (localContractItem) return localContractItem.amounts[localContractTermIndex] ?? 0;
  }
  return undefined;
}

function createPricingGridItem(
  sku: number,
  item: IItem | undefined,
  supplierId: number | undefined,
  caseListCost: number | undefined,
  quantity: number | undefined
): PG.IItem {
  if (!item) {
    return {
      sku: sku,
      upc: 0,
      description: 'Unknown Item',
      displaySize: '',
    };
  }

  return {
    packSize: quantity,
    sku: sku,
    upc: item.upc,
    description: item.description,
    displaySize: `${item.size} ${item.unitOfMeasure}`,
    priceAssociation: item.suppliers?.find((s) => s.id === supplierId)?.priceAssociationCode,
    caseCost: caseListCost,
    orderCode: item.orderCode,
  };
}

function extractPricesBySku(sku: number, pricings?: IPricing[]): (PG.IPrice | undefined)[] {
  if (!pricings) return [];
  const result: (PG.IPrice | undefined)[] = [];

  pricings.forEach((price) => {
    const itemPrice = price.items.find((i) => i.sku === sku);

    result.push(
      itemPrice
        ? { multiple: itemPrice.multiple, price: itemPrice.price, orderSurvey: itemPrice.orderSurvey }
        : { multiple: 1, price: 0, orderSurvey: false }
    );
  });
  return result;
}

export function getPricingName(pricing: IPricing) {
  return `pricing_${pricing.pricingId ?? pricing.uniqueId}`;
}

function computeCaseCostForRow(row: PricingGridPricingItem, p: IPricing, referenceData: IReferenceDataState): number | undefined {
  if (!row.costImplications) return row.item.caseCost || undefined;

  return computeCaseCostForPricingGridCostImplications(row.item.caseCost, row.costImplications, p.itemTerms, referenceData);
}

function computeCaseCostForPricingGridCostImplications(
  itemCaseListCost: number | undefined,
  costImplications: (PG.ICostImplication | undefined)[],
  itemTerms: IPricingItemTerm[],
  referenceData: IReferenceDataState
) {
  return computeCaseCost(itemCaseListCost, createPricingCostImplicationsFromGridType(costImplications, itemTerms, referenceData));
}

function computeCaseCost(
  itemCaseListCost: number | undefined,
  selectedCostImplications: (IPricingCostImplication | undefined)[]
): number | undefined {
  if (itemCaseListCost === undefined) return undefined;

  return itemCaseListCost - computeCaseReductions(selectedCostImplications);
}

function computeCaseReductions(selectedCostImplications: (IPricingCostImplication | undefined)[]): number {
  return selectedCostImplications.reduce((subtotal, ci) => {
    if (!ci) return subtotal;
    if (ci.type !== CostImplicationType.CASE) return subtotal;

    return subtotal + ci.amount;
  }, 0);
}

function computeUnitCostForRow(row: PricingGridPricingItem, p: IPricing, referenceData: IReferenceDataState): number | undefined {
  return computeUnitCostForPricingGridCostImplications(row.item, row.costImplications, p.itemTerms, referenceData);
}

function computeUnitCostForPricingGridCostImplications(
  item: PG.IItem,
  costImplications: (PG.ICostImplication | undefined)[] | undefined,
  itemTerms: IPricingItemTerm[],
  referenceData: IReferenceDataState
): number | undefined {
  return computeUnitCostForPricingGridItem(item, createPricingCostImplicationsFromGridType(costImplications, itemTerms, referenceData));
}

function computeUnitCostForPricingGridItem(
  item: PG.IItem,
  selectedCostImplications: (IPricingCostImplication | undefined)[] | undefined
): number | undefined {
  return computeUnitCost(item.caseCost, item.packSize, selectedCostImplications);
}

function computeUnitCost(
  caseListCost: number | undefined,
  quantityPerPack: number | undefined,
  selectedCostImplications: (IPricingCostImplication | undefined)[] | undefined
): number | undefined {
  if (!caseListCost || !quantityPerPack) return undefined;
  const unadjustedUnitCost = caseListCost / quantityPerPack;
  return unadjustedUnitCost - computeUnitReductions(quantityPerPack, selectedCostImplications);
}

function computeUnitReductions(
  quantityPerPack: number | undefined,
  costImplications: (IPricingCostImplication | undefined)[] | undefined //selected only
): number {
  if (!costImplications || !quantityPerPack) return 0;

  return costImplications.reduce((subtotal, ci) => {
    if (!ci) return subtotal;
    if (ci.type === CostImplicationType.CASE) {
      return subtotal + ci.amount / quantityPerPack;
    } else if (ci.type === CostImplicationType.SINGLEUNIT) {
      return subtotal + ci.amount;
    }

    return subtotal;
  }, 0);
}

function computeUnitOnlyReductions(
  quantityPerPack: number | undefined,
  costImplications: (IPricingCostImplication | undefined)[] | undefined //selected only
): number {
  if (!costImplications || !quantityPerPack) return 0;

  return costImplications.reduce((subtotal, ci) => {
    if (!ci) return subtotal;
    if (ci.type === CostImplicationType.SINGLEUNIT) {
      return subtotal + ci.amount;
    }

    return subtotal;
  }, 0);
}

function createPricingCostImplicationsFromGridType(
  costImplications: (PG.ICostImplication | undefined)[] | undefined,
  itemTerms: IPricingItemTerm[],
  referenceData: IReferenceDataState
): IPricingCostImplication[] {
  if (!costImplications) return [];

  const results: IPricingCostImplication[] = [];
  for (const ci of costImplications) {
    if (!ci) continue;
    const itemTerm = itemTerms.find((it) => it.itemTermUniqueId === ci.uniqueId);
    if (!itemTerm) continue;
    const type = getCostImplicationType(itemTerm.termUnitOfMeasureId, referenceData);
    results.push({ ...ci, type: type, itemTerm: itemTerm });
  }
  return results;
}

export function getPricingColumnConfigurations(
  canModify: boolean,
  contract?: IContract,
  referenceData?: IReferenceDataState,
  classes?: Record<'headerContents' | 'headerEditBtn' | 'root' | 'headerComments' | 'suggestedRetail' | 'pricePrefix', string>,
  invokePricingEdit?: (pricing: IPricing) => void,
  updatePricingItems?: (pricingIndex: number, items: IPricingItem[]) => void
): PG.IPricingColumnConfiguration<PG.IPricingItem>[] {
  if (!contract || !contract.pricings || !referenceData) return [];

  return contract.pricings.map<PG.IPricingColumnConfiguration<PG.IPricingItem>>((p, index) => ({
    name: getPricingName(p),
    priceType: p.priceTypeId,
    startDate: p.startDate,
    endDate: p.endDate,
    comments: p.comments,
    caseCost: (row: PricingGridPricingItem) => computeCaseCostForRow(row, p, referenceData),
    unitCost: (row: PricingGridPricingItem) => computeUnitCostForRow(row, p, referenceData),
    additionalSubColumns: [
      {
        field: `orderSurvey_${index}`,
        type: 'boolean',
        columnBodyCss: {
          alignItems: 'center',
        },
        width: 100,
        editable: true,
        hideable: false,
        editableCellOverride: (params: DataGridEditableCellOverrideParams<number, PG.IPricingItem>) => {
          if (params.row.rowType === 'Parent') {
            return {
              isEditable: true,
              cellCss: {
                backgroundColor: defaultColors.editGreen,
                '& div[role=checkbox]': {
                  justifyContent: 'center',
                },
              },
            };
          } else {
            return {
              isEditable: false,
              cellCss: {
                backgroundColor: 'inherit',
                borderBottom: `1px solid ${defaultColors.grey}`,
              },
            };
          }
        },
        renderHeaderCellContent: () => {
          if (!contract.pricings) return;
          //Determine Select All checkbox intial checked state for this pricing.
          const checkBoxChecked = contract.pricings[index].items.every((i) => i.orderSurvey);

          return (
            <Checkbox
              id={`selectAll_pricing${index}`}
              label={'Order Survey'}
              initialValue={checkBoxChecked}
              checked={checkBoxChecked}
              disabled={!canModify}
              onChange={handleSelectionChange}
            />
          );

          function handleSelectionChange(checked: boolean) {
            updatePricingItems?.(
              index,
              p.items.map((item) => ({ ...item, orderSurvey: checked }))
            );
          }
        },
        valueGetter(params: DataGridValueGetterParams<PricingGridPricingItem>) {
          if (!params.row) return false;
          const price = params.row.prices[index];
          if (price == undefined) return false;
          return price.orderSurvey;
        },
        valueSetter(params: DataGridValueSetterParams<boolean | undefined, PricingGridPricingItem>) {
          let value: boolean | undefined = undefined;
          if (params.value != undefined) {
            value = params.value;
          }
          const prices = [...params.row.prices];
          let price = prices[index];

          if (price == undefined || !price) price = {};
          if (price.orderSurvey === value) return params.row;

          price.orderSurvey = value;
          prices[index] = price;
          return { ...params.row, prices };
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<boolean, PricingGridPricingItem>) => {
          if (params.row.rowType === 'Parent') return params.row.prices[index]?.orderSurvey?.toString() ?? 'false';
          else '';
        },
      },
    ],
    groupedHeaderCellCss: {
      root: {
        marginTop: '10px',
        height: '150px',
        backgroundColor: defaultColors.white,
        borderRadius: '16px 16px 0 0',
        outline: `1px solid ${defaultColors.grey}`,
        fontSize: '13px',
        color: defaultColors.mediumGrey,
      },
      subCell: {
        borderTop: 'unset',
      },
    },
    renderHeaderCellContent: () => {
      const adLocation = referenceData.adSites.all.find((a) => a.adSiteId === p.adSiteId);
      return (
        <div className={classes?.headerContents}>
          <Button classes={{ root: classes?.headerEditBtn }} id={`editPricing${p.uniqueId}`} onClick={() => invokePricingEdit?.(p)} variant={'link'}>
            {referenceData.priceTypes.byId[p.priceTypeId].name} {adLocation && ` - ${adLocation.name}`}
          </Button>
          <div>
            {format(p.startDate, 'M/d/yy')} - {format(p.endDate, 'M/d/yy')}
          </div>
          <div title={p.itemTerms.map((it) => referenceData.termTypes.byId[it.termTypeId].name).join(', ')}>
            Cost Implications: {p.itemTerms.length}
          </div>
          <div className={classes?.headerComments} title={p.comments}>
            Comments: {p.comments}
          </div>
        </div>
      );
    },
    costImplications: p.itemTerms
      .filter((it) => {
        //if same contract, and term doesn't exist (deleted during current session), don't render the column
        if (it.contract.contractId === contract.contractId) {
          const termExists = contract.terms.contractTermsForItem.some((cti) => cti.uniqueId === it.itemTermUniqueId);
          return termExists;
        }
        return true;
      })
      .map<PG.ICostImplicationConfiguration>((it) => ({
        uniqueId: it.itemTermUniqueId,
        name: referenceData.termTypes.byId[it.termTypeId].name,
        accountingType: PG.AccountingType.CREDIT,
        type: getCostImplicationType(it.termUnitOfMeasureId, referenceData),
        renderHeaderCellContent: () => {
          return (
            <>
              <span
                title={`Internal Contract # ${it.contract.contractId} - ${referenceData.termTypes.byId[it.termTypeId].name} / ${it.itemTermUniqueId}`}
              >
                {referenceData.termTypes.byId[it.termTypeId].name}
              </span>
            </>
          );
        },
      })),
  }));
}

export function toValidationIssue(cellMessage: DataGridCellMessage, scope: string) {
  return new ValidationIssue({
    scope,
    source: ValidationSource.Ui,
    field: cellMessage.field,
    identifier: cellMessage.rowId,
    message: cellMessage.message,
  });
}
