import { PricingGridProps, usePricingGridApi } from '@dierbergs-markets/react-feature-library';
import { getContractPricingItems, getPricingColumnConfigurations } from '../../../../../../utilities/PricingUtilities';
import { IContract, IPricing, IPricingItem } from '../../../../../../models';
import { useApplicationContextState } from '../../../../../../contexts/ApplicationContext';
import { useMemo } from 'react';
import { tss } from 'tss-react';
import { defaultColors } from '../../../../../../styles/variables';

/**
 * Configuration props for the usePricingGridConfig hook
 */
export interface PricingGridConfigProps {
  /** The contract to generate pricing configuration for */
  contract?: IContract;
  /** Whether pricing data can be modified */
  canModify: boolean;
  /** Optional callback when pricing is edited */
  invokePricingEdit?: (pricing: IPricing) => void;
  /** Optional callback when pricing items are updated */
  updatePricingItems?: (pricingIndex: number, items: IPricingItem[]) => void;
}

/**
 * A hook that provides configuration and validation for the PricingGrid component.
 * This hook serves two main purposes:
 * 1. It provides the necessary props for the PricingGrid component
 * 2. It exposes validation functionality that can be used independently of the grid UI
 *
 * @param props - Configuration options for pricing grid
 * @returns An object containing:
 *  - basePricingGridProps: Props required by the PricingGrid component
 *  - runInternalValidation: Function to validate pricing data without requiring grid mounting
 *
 * @example
 * ```tsx
 * // Using in a component with mounted PricingGrid
 * const { basePricingGridProps } = usePricingGridConfig({
 *   contract,
 *   canModify: true
 * });
 *
 * return <PricingGrid {...basePricingGridProps} />;
 *
 * // Using for validation only
 * const { runInternalValidation } = usePricingGridConfig({
 *   contract,
 *   canModify: false
 * });
 *
 * useEffect(() => {
 *   const validationResults = runInternalValidation();
 *   // Handle validation results...
 * }, [runInternalValidation]);
 * ```
 */
export default function usePricingGridConfig(props: PricingGridConfigProps) {
  const { referenceData } = useApplicationContextState();
  const { classes } = useStyles();

  const { contract, canModify, invokePricingEdit, updatePricingItems } = props;

  const pricingConfig = useMemo(() => {
    if (!referenceData || !contract) return { additionalColumns: [], rows: [], prices: [] };

    const prices = getPricingColumnConfigurations(canModify, contract, referenceData, classes, invokePricingEdit, updatePricingItems);
    const rows = getContractPricingItems(contract, referenceData);

    return { additionalColumns: [], prices, rows };
  }, [contract, referenceData]);

  const { runInternalValidation } = usePricingGridApi(pricingConfig);

  const basePricingGridProps: PricingGridProps = {
    id: '',
    rows: pricingConfig?.rows ?? [],
    prices: pricingConfig?.prices ?? [],
  };

  return {
    basePricingGridProps,
    runInternalValidation,
  };
}

const useStyles = tss.create({
  root: {},

  headerContents: {
    margin: '15px',
    marginBottom: '10px',
    marginLeft: '20px',
    width: '100%',
  },
  headerComments: {
    width: '280px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerEditBtn: {
    '&&': {
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      color: defaultColors.blue,
      paddingBottom: '5px',
    },
  },
  suggestedRetail: {
    paddingTop: '8px',
  },
  pricePrefix: {
    paddingRight: '8px',
    fontWeight: 500,
  },
});
