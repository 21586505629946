import { v4 } from 'uuid';
import { IPricing, IPricingItem, IPricingRequestResponse } from '../../models';
import { fetchGetJson, HttpErrorResponse } from '../contractHubApi';

export const contractPricingService = {
  getPricing,
};

async function getPricing(contractId: number): Promise<IPricing[] | HttpErrorResponse> {
  const response = await fetchGetJson<IPricingRequestResponse[]>({ endpoint: `contract/${contractId}/pricing` });
  if (response instanceof HttpErrorResponse) return response;

  return response.map(
    (p) =>
      ({
        pricingId: p.pricingId,
        uniqueId: v4(), //use stored value?
        priceTypeId: p.priceTypeId,
        adSiteId: p.adSiteId,
        startDate: p.startDate,
        endDate: p.endDate,
        comments: p.comments,
        itemTerms: p.itemTerms,
        items:
          p.areas.length === 0
            ? []
            : p.areas[0].items.map(
                (i) =>
                  ({
                    sku: i.sku,
                    price: i.price,
                    multiple: i.multiple,
                    orderSurvey: p.orderSurveyItemSkus.includes(i.sku),
                  }) as IPricingItem
              ),
      }) as IPricing
  );
}
