import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ValidationIssue } from '../../classes/ValidationIssue';
import { ValidationScopes } from '../../models/enums';

export interface ContractValidationStore {
  showValidationIssues: boolean;
  validationIssues: ValidationIssue[];
  hasInternalPricingGridErrors: boolean;
  hasInternalUpcDataGridErrors: boolean;
  setHasInternalPricingGridErrors: (value: boolean) => void;
  setHasInternalUpcDataGridErrors: (value: boolean) => void;
  hasPricingErrors: () => boolean;
  hasUpcErrors: () => boolean;
  hasLumpSumErrors: () => boolean;
  setShowValidationIssues: (value: boolean) => void;
  setValidationIssues: (value: ValidationIssue[]) => void;
  setValidationIssuesByIdentifierScope: (identifier: string, validationScope: string, validationIssues: ValidationIssue[]) => void;
  clearAllValidationIssues: () => void;
  resetState: () => void;
}

const InitialState = {
  showValidationIssues: false,
  hasInternalPricingGridErrors: false,
  hasInternalUpcDataGridErrors: false,
  validationIssues: [],
};

export const useContractValidationStore = create<ContractValidationStore>()(
  devtools(
    (set, get) => ({
      ...InitialState,
      hasPricingErrors: () => {
        const state = get();
        const hasPricingValidationIssues = state.validationIssues.some((issue) => issue.scope === ValidationScopes.Pricings);
        return state.hasInternalPricingGridErrors || hasPricingValidationIssues;
      },
      hasUpcErrors: () => {
        const state = get();
        const hasUpcValidationIssues = state.validationIssues.some((issue) =>
          [ValidationScopes.ContractItem, ValidationScopes.ContractItems, ValidationScopes.ContractTermsForItem].includes(issue.scope)
        );
        return state.hasInternalUpcDataGridErrors || (state.showValidationIssues && hasUpcValidationIssues);
      },
      hasLumpSumErrors: () => {
        const state = get();
        const hasLumpValidationIssues = state.validationIssues.some((issue) => [ValidationScopes.ContractTermsForLumpSum].includes(issue.scope));
        return state.showValidationIssues && hasLumpValidationIssues;
      },
      setValidationIssues: (value: ValidationIssue[]) => set(() => ({ validationIssues: value }), false, 'setValidationIssues'),
      setValidationIssuesByIdentifierScope: (identifier: string | number, validationScope: string, validationIssues: ValidationIssue[]) =>
        set(
          (state) => {
            const otherValidationIssues = state.validationIssues.filter(
              (issue) => !(issue.identifier === identifier && issue.scope === validationScope)
            );

            const revisedValidationIssues = [...otherValidationIssues, ...validationIssues];
            return {
              validationIssues: revisedValidationIssues,
            };
          },
          false,
          'setValidationIssuesByIdentifierScope'
        ),
      clearAllValidationIssues: () =>
        set(
          (state) => {
            return {
              validationIssues: [],
            };
          },
          false,
          'clearAllValidationIssues'
        ),
      setShowValidationIssues: (value: boolean) => set(() => ({ showValidationIssues: value }), false, 'setShowValidationIssues'),
      setHasInternalPricingGridErrors: (value: boolean) =>
        set(() => ({ hasInternalPricingGridErrors: value }), false, 'setHasInternalPricingGridErrors'),
      setHasInternalUpcDataGridErrors: (value: boolean) =>
        set(() => ({ hasInternalUpcDataGridErrors: value }), false, 'setHasInternalUpcDataGridErrors'),
      resetState: () => set({ ...InitialState }, false, 'resetState'),
    }),
    { name: 'contract-validation-state' }
  )
);
