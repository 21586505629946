import { memo } from 'react';
import { IPricingSource, PricingGridPricingItem } from '../../../../../utilities/PricingUtilities';
import { CoreDialog, CoreDialogContent, CoreDialogTitle } from '@dierbergs-markets/react-component-library';

interface IProps {
  selectedRow: PricingGridPricingItem | null;
  onClose: () => void;
}

function ShipperCostGrid(props: { source: IPricingSource; pricingItem: PricingGridPricingItem | null }) {
  const s = props.source;
  if (!s.shipper) return <></>;

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>{s.shipper.item.description}</div>
      <table border={1} cellSpacing={0}>
        <thead>
          <tr>
            <th>UPC</th>
            <th>Description</th>
            <th>Pack</th>
            <th>Reg. Cost</th>
            <th>Reg. Case</th>
            <th>Ratio</th>
            <th>Case List Cost</th>
          </tr>
        </thead>
        <tbody>
          {s.shipper.contents.map((c) => (
            <tr key={c.item?.sku} style={c.item?.upc === props.pricingItem?.item.upc ? { backgroundColor: '#FFDDAA' } : {}}>
              <td>{c.item?.upc}</td>
              <td>{c.item?.description}</td>
              <td>{c.quantity}</td>
              <td>{c.regularUnitCost?.toFixed(2)}</td>
              <td>{c.quantity && c.regularUnitCost ? (c.quantity * c.regularUnitCost).toFixed(2) : ''}</td>
              <td>{c.ratio ? (c.ratio * 100).toFixed(1) + '%' : ''}</td>
              <td>{c.caseListCost?.toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>Shipper / Total</td>
            <td>{s.shipper.contents.reduce((sum, c) => sum + (c.quantity || 0), 0)}</td>
            <td></td>
            <td>
              {s.shipper.contents.reduce((sum, c) => sum + (c.quantity && c.regularUnitCost ? c.quantity * c.regularUnitCost : 0), 0).toFixed(2)}
            </td>
            <td></td>
            <td>{s.shipper.caseListCost?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const CostImplicationGrid = function (props: { pricingItem: PricingGridPricingItem | null }) {
  if (!props.pricingItem) return <></>;

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>Cost Implications</div>
      <div style={{ fontStyle: 'italic' }}>Highest unit cost is selected for lowest possible margin.</div>
      <table border={1} cellSpacing={0}>
        <thead>
          <tr>
            <th>UPC</th>
            <th>Description</th>
            <th>Pack</th>
            <th>Case List Cost</th>
            <th>- / Case</th>
            <th>Case Cost</th>
            <th>Per Item</th>
            <th>- / Unit</th>
            <th>Unit Cost</th>
          </tr>
        </thead>
        <tbody>
          {props.pricingItem.sources.map((s) => (
            <tr
              key={s.shipper?.item.sku}
              style={
                (props.pricingItem?.source?.shipper ? props.pricingItem?.source?.shipper?.item.upc === s.shipper?.item.upc : !s.shipper)
                  ? { backgroundColor: '#FFDDAA' }
                  : {}
              }
            >
              <td>{s.shipper ? s.shipper.item?.upc : props.pricingItem?.item.upc}</td>
              <td>{s.shipper ? s.shipper.item?.description : 'Open Stock'}</td>
              <td>{s.quantity}</td>
              <td>{s.caseListCost?.toFixed(2)}</td>
              <td>{s.caseReductions.toFixed(2)}</td>
              <td>{s.caseCost?.toFixed(2)}</td>
              <td>{s.caseCost ? (s.caseCost / s.quantity).toFixed(2) : ''}</td>
              <td>{s.unitReductions.toFixed(2)}</td>
              <td>{s.unitCost?.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PricingSourceDialog = memo((props: IProps) => {
  if (!props.selectedRow) return <></>;

  return (
    <CoreDialog id="pricingSourceDialog" open={!!props.selectedRow} onClose={props.onClose}>
      <CoreDialogTitle>Purchasing Sources</CoreDialogTitle>
      <CoreDialogContent>
        <CostImplicationGrid pricingItem={props.selectedRow} />
        <br />
        {props.selectedRow.sources
          .filter((s) => s.shipper)
          .map((s) => (
            <>
              <ShipperCostGrid pricingItem={props.selectedRow} source={s} />
              <br />
            </>
          ))}
      </CoreDialogContent>
    </CoreDialog>
  );
});

export default PricingSourceDialog;
