import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ContractPageTabs } from '../../../../models/enums';
import { defaultColors } from '../../../../styles/variables';
import { useContractValidationStore } from '../../../../store/contract/contractValidationStore';

interface TabErrorIconProps {
  tabSelection: string;
}

export function TabErrorIcon(props: TabErrorIconProps) {
  const hasUpcErrors = useContractValidationStore((state) => state.hasUpcErrors());
  const hasPricingErrors = useContractValidationStore((state) => state.hasPricingErrors());
  const hasLumpSumErrors = useContractValidationStore((state) => state.hasLumpSumErrors());

  const iconElement = (
    <ErrorOutlineIcon
      style={{
        marginLeft: '0px !important',
        marginBottom: '10px',
        color: defaultColors.red,
        fontSize: '16px',
      }}
    />
  );

  switch (props.tabSelection) {
    case ContractPageTabs.Upc:
      if (hasUpcErrors) return iconElement;
      break;
    case ContractPageTabs.LumpSum:
      if (hasLumpSumErrors) return iconElement;
      break;
    case ContractPageTabs.Pricing:
      if (hasPricingErrors) return iconElement;
      break;

    default:
      return <></>;
  }

  return null;
}
