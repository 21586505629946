import { IReferenceDataState } from '../contexts/ApplicationContext';
import { IStoreSelection, IStore, IPricingAreaItemRequestResponse, IPricingRequestResponse, IPricing } from '../models';

export function getDistinctStores(storeSelections: IStoreSelection, referenceData?: IReferenceDataState): IStore[] {
  const { storeIds = [], storeGroupIds = [] } = storeSelections;

  if (!referenceData || !referenceData?.stores || !referenceData?.storeGroups) {
    return [];
  }

  // Get all store numbers based on storeGroupIds
  const storeNumbers: number[] = [];
  storeGroupIds.forEach((groupId) => {
    const group = referenceData.storeGroups.byId[groupId];
    if (group) storeNumbers.push(...group.storeNumbers);
  });

  // Add storeIds to storeNumbers
  storeIds.forEach((storeId) => {
    if (!storeNumbers.includes(storeId)) storeNumbers.push(storeId);
  });

  // Filter out duplicate storeNumbers
  const distinctStoreNumbers = Array.from(new Set(storeNumbers));

  // Retrieve store information based on storeNumbers
  const distinctStores: IStore[] = distinctStoreNumbers
    .map((storeNumber) => {
      const store = referenceData.stores.byId[storeNumber];
      return store ? { id: store.id, name: store.name, displayName: store.displayName } : null;
    })
    .filter((store) => store !== null) as IStore[]; // Filter out null entries

  return distinctStores.sort((a, b) => a.displayName.localeCompare(b.displayName));
}

export function convertPricings(
  pricings: IPricing[] | undefined,
  stores: IStoreSelection,
  referenceData: IReferenceDataState
): IPricingRequestResponse[] | undefined {
  if (!pricings) return undefined;

  return pricings.map(
    (p) =>
      ({
        pricingId: p.pricingId,
        priceTypeId: p.priceTypeId,
        startDate: p.startDate,
        endDate: p.endDate,
        comments: p.comments,
        adSiteId: p.adSiteId,
        orderSurveyItemSkus: p.items.filter((i) => i.orderSurvey).map((i) => i.sku),
        itemTerms: p.itemTerms,
        areas: [
          {
            stores: stores.storeIds.concat(stores.storeGroupIds.flatMap((sgId) => referenceData.storeGroups.byId[sgId].storeNumbers)),
            items: p.items.map(
              (i) =>
                ({
                  sku: i.sku,
                  price: i.price,
                  multiple: i.multiple,
                }) as IPricingAreaItemRequestResponse
            ),
          },
        ],
      }) as IPricingRequestResponse
  );
}
